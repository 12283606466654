import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import imgHeader from '../images/hero_header.png'
import cards1 from '../images/card-1.png'
import cards2 from '../images/card-2.png'
import cards3 from '../images/card-3.png'
import cards4 from '../images/card-4.png'
import cards5 from '../images/card-5.png'
import cards6 from '../images/card-6.png'
import cards7 from '../images/card-7.png'
import cards8 from '../images/card-8.png'
import cards9 from '../images/card-9.png'

import '../scss/index.scss'

const IndexPage = () => {
  return (
    <Layout mainClass="page__home">
      <SEO title="Designer d'interfaces humaines" />
      <section className="block__introduction">
        <div className="wrapper">
          <div className="introduction__leftPart">
            <h1 className="introduction__title">Bonjour ! Je suis Kary LE, designer d'interfaces.</h1>
            <p>
              Spécialisée dans le web et le mobile j’aime être présente à toutes les étapes du processus de création. Mon objectif : créer des interfaces aussi humaines que possible.
            </p>

            <div className="gradient__decoration"></div>
          </div>
          <div className="introduction__rightPart">
            <img src={imgHeader} alt="Kary LE - Designer d'interfaces"/>
          </div>
        </div>
      </section>

      <section className="block__portfolio" id="portfolio">
        <div className="wrapper">
          <div className="portfolio__leftPart">
            <a href="https://delarydraw.com" target="_blank" rel="noopener noreferrer" className="portfolio__item">
              <img src={cards9} alt="Delary Draw"/>
            </a>

            <a href="https://www.behance.net/gallery/84996825/ETS-GABRIEL" target="_blank" rel="noopener noreferrer" className="portfolio__item">
              <img src={cards1} alt="Ets. Gabriel"/>
            </a>

            <a href="https://www.behance.net/gallery/76383755/Virtual-Vet" target="_blank" rel="noopener noreferrer" className="portfolio__item">
              <img src={cards2} alt="Virtual Vet"/>
            </a>

            <a href="https://www.behance.net/gallery/80753993/Daily-Ui" target="_blank" rel="noopener noreferrer" className="portfolio__item">
              <img src={cards3} alt="Daily UI"/>
            </a>
          </div>

          <div className="portfolio__rightPart">
            <div className="portfolio__intro">
              <h2 className="portfolio__title">Mon portfolio</h2>
              <p>
                Découvrez les projets sur lesquels j’ai travaillé et dont je suis le plus fière.
              </p>
              <a href="https://www.behance.net/karyle" className="btn" target="_blank" rel="noopener noreferrer">Tout voir sur Behance</a>
            </div>
            <div className="portfolio__items items__left">
              <a href="https://www.behance.net/gallery/86050613/Sprochrenner" target="_blank" rel="noopener noreferrer" className="portfolio__item">
                <img src={cards4} alt="Sprochrenner"/>
              </a>

              <a href="https://www.behance.net/gallery/74042253/Hey-Chat-" target="_blank" rel="noopener noreferrer" className="portfolio__item">
                <img src={cards5} alt="Hey Chat!"/>
              </a>

              <a href="https://www.behance.net/gallery/87749855/Inktober-2019" target="_blank" rel="noopener noreferrer" className="portfolio__item">
                <img src={cards6} alt="Illustration"/>
              </a>
            </div>

            <div className="portfolio__items items__right">
              <a href="https://www.behance.net/gallery/80222769/Lujo-Property" target="_blank" rel="noopener noreferrer" className="portfolio__item">
                <img src={cards7} alt="Lujo Property"/>
              </a>

              <a href="https://www.behance.net/gallery/73788245/French-adventure" target="_blank" rel="noopener noreferrer" className="portfolio__item">
                <img src={cards8} alt="French Adventure"/>
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
